/* eslint-disable no-eval */
import { useTheme } from '@emotion/react';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Collapse, Grid, Toolbar } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { v4 } from 'uuid';
import FileInput from '../../../Components/FileUpload/index.js';
import ListDataUI from '../../../Components/ListDataUI/index.js';
import BottomComponent from '../../../Components/UI/bottomComponent.js';
import MainCard from '../../../Components/UI/mainCard.js';
import { getDataOfCompletedForm, uploadFile } from '../../../Config/api.js';
import { apiEndpoints } from '../../../Config/routes.js';
import { UIStore } from '../../../Config/store.js';
import { convertEventValueToUpperCase, trimFields } from '../../../Helper/helpers.js';
import { useIsDesktop } from '../../../Helper/hooks.js';
import { mixpanel, trackingEvents } from '../../../Monitoring/Mixpanel/index.js';
import { statesEnum } from '../../../Utils/enums.js';

/* eslint-disable no-eval */
import { CancelOutlined } from '@mui/icons-material';
import { Checkbox, FormControlLabel, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import Captcha from '../../../Components/Captcha/index.js';
import MarkdownWrapper from '../../../Components/UI/markdownWrapper.js';
import { addProfileVerificationData, getAxiosHeader, getVerificationFromProfileSync, getWallet, updateWalletAndStartFormProcess } from '../../../Config/api.js';
import { axiosInstanceWithoutBaseUrl } from '../../../Config/axios.js';
import { fileInputConfig } from '../../../Config/dataUi.js';
import { updateStore } from '../../../Config/store.js';
import { displayTime } from '../../../Helper/dates.js';
import { customForms } from '../customForms/index.js';
import { listOfSideProcesses } from '../sideProcesses.js';
import { ConstructFormInputs } from './constructFormInputs.js';

export default function Form({ edit, c, data, page, setPage, formResponses, setFormResponses,
  skip, subTypes, setSubTypesWithEmptyFiles, oldItems = [], setSubTypes, getDataFromProfile }) {
  const theme = useTheme();
  const { state } = useLocation();
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const customUiDataToSubmitRef = useRef([]);
  const refForChildFunctionCustomUiTrigger = useRef([]);
  const form = UIStore.useState((s) => s.form);
  const editData = UIStore.useState((s) => s.editData);
  const currentWorkingStepperStep = UIStore.useState((s) => s.currentWorkingStepperStep);
  const userInput = UIStore.useState((s) => s.userInput);
  const formInfo = UIStore.useState((s) => s.formInfo);
  // const fieldContent = UIStore.useState((s) => s.fieldContent);
  const docData = UIStore.useState((s) => s.docData);
  const currentCredentialUnderFetch = form?.list?.[currentWorkingStepperStep];
  const currentUserData = UIStore.useState((s) => s.currentUserData);
  const searchedQuery = UIStore.useState((s) => s.searchedQuery);
  const [recordsSet, setRecordsSet] = useState([]);
  const [recordsSetStale, setRecordsSetStale] = useState([]);
  const [markRecordsAsDone, setMarkRecordsAsDone] = useState({});
  const [loadingInCustomForms, setLoadingInCustomForms] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [error, setError] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [bgvOpted, setBgvOpted] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loadingSkip, setLoadingSkip] = React.useState(false);
  const [progress, setProgress] = React.useState([0]);
  const [captchaId, setCaptchaId] = useState(null);
  const [captchaInput, setCaptchaInput] = useState('');
  const [address, setAddress] = useState(null);
  const inputs = c[page]?.inputs?.filter((zz) => checkVisibility(formInfo?.[currentCredentialUnderFetch?.key], zz?.visibility));
  console.log('state::', state);

  const getTxOrgId = () => {
    return {
      orgId: searchedQuery?.orgId,
      txnId: searchedQuery?.txnId
    };
  };

  const constructFields = () => {
    const id = v4();
    return c[page]?.inputs?.reduce((acc, item) => {
      if (checkVisibility(formInfo?.[currentCredentialUnderFetch?.key], item?.visibility)) {
        acc[item.fieldName] = '';
      }
      return acc;
    }, {
      privateData2: {
        id
      }
    });
  };

  console.log('edit::', edit, editData, recordsSet);


  const populateFields = (preConstructedData) => {
    const x = preConstructedData ? { ...preConstructedData } : {};
    if (preConstructedData?.documentList?.length > 0) {
      preConstructedData?.documentList?.forEach((item) => {
        x[item.label] = item.url;
      });
    }

    if (Array.isArray(preConstructedData?.document) && preConstructedData?.document?.length > 0) {
      preConstructedData?.document?.forEach((item) => {
        x[item.label] = item.url;
      });
    } else if (typeof preConstructedData?.document === 'object') {
      x.documentUrl = preConstructedData?.document?.url;
    }

    if (typeof preConstructedData?.document === 'object') {
      x.documentUrl = preConstructedData?.document?.url;
    }


    if (formInfo?.[currentCredentialUnderFetch.key]?.marksheetRequired) {
      x['Marksheet2'] = subTypes[edit?.uid];
    }
    console.log('edit::22', x);
    setRecordsSet([{ ...x }]);
    setRecordsSetStale([{ ...x }]);
  };

  useEffect(() => {
    if (edit) {
      populateFields(editData?.data);
    }
    else if (
      (formInfo?.[currentCredentialUnderFetch.key]?.state === statesEnum.NOT_INITIALIZED) &&
      !edit && oldItems.length <= 0 && form?.state !== statesEnum.NOT_INITIALIZED) {
      const subData = getDataOfCompletedForm(currentCredentialUnderFetch.key, searchedQuery?.orgId, searchedQuery?.txnId)
          .then((s) => {
            console.log('subData::', s);
            if (s?.data?.[0]?.request?.data) {
              populateFields(s?.data?.[0]?.request?.data);
            }
          });
    }
    else {
      setRecordsSet([]);
    }
    setBgvOpted(false);
    setMarkRecordsAsDone({});

    // updateStore('fieldContent', temp);
  }, [c, page, edit, editData]);

  useEffect(() => {
    if (
      (formInfo?.[currentCredentialUnderFetch.key]?.state === statesEnum.NOT_INITIALIZED) &&
      !edit && oldItems.length <= 0) {
      setRecordsSet([constructFields()]);
    } else {
      // setRecordsSet([]);
    }
  }, [oldItems]);

  const updateField = (fieldName, event, indexOfRecordSet, makeUpperCase = false, deleteThisKey = false) => {
    if (makeUpperCase) {
      event = convertEventValueToUpperCase(event);
    }
    const temp1 = { ...recordsSet[indexOfRecordSet] };
    if (!deleteThisKey) {
      temp1[fieldName] = event?.target?.value;
    } else {
      delete temp1[fieldName];
    }
    const temp2 = [...recordsSet];
    console.log('TEMP:1', temp2, temp1);
    temp2[indexOfRecordSet] = temp1;
    console.log('TEMP:2', temp2);
    setRecordsSet(temp2);
    // updateStore('fieldContent', temp);
  };

  const submitData = async () => {
    // please add atleast 1 document
    if (oldItems?.length <= 0 && recordsSet?.length <= 0) {
      window.snackbar('error', 'Please add atleast one document');
      return null;
    }

    setLoading(true);
    let currentFormCount = 0;// count for iterating over recordsSet(forms)
    const tempFieldsArray = [];// array to store the modified, validated records from recordsSet(forms)
    const tempErr = [];// array to store errors in each recordSet
    const newSubTypes = [];// array to store the backend generated subType id of each new form submitted
    let extraDataToSend = {};// stores the data to submit, generated by custom forms, like address edit component
    const uploadFilesHere = [];// array to store the files, encountered during validationg each record(form)

    // if marksheet required and edit is false
    if (formInfo?.[currentCredentialUnderFetch.key]?.marksheetRequired && !edit) {
      // check if files are uploaded or not for existing ones(oldDataFound)
      const emptyUrls = Object.keys(subTypes).filter((x) => !subTypes[x]);
      console.log('emptyUrls', emptyUrls);
      if (emptyUrls?.length > 0) {
        setSubTypesWithEmptyFiles(emptyUrls);
        window.snackbar('error', 'Please add Marksheet for all the documents');
        return null;
      }
    }

    // check if there are any customForms, trigger its submitting function, check if it returns true, save the data in extraDataToSend
    if (data?.showSpecificForm && data?.showSpecificForm?.length > 0) {
      let doAllPass = true;
      for await (const xx of refForChildFunctionCustomUiTrigger.current) {
        const valid = await xx?.submit();
        if (valid) {
          doAllPass = true;
          // throw new Error('Validation Error');
        } else {
          doAllPass = false;
          return null;
        }
      }
      if (!doAllPass) {
        mixpanel.track(trackingEvents.verification.events.customFormsValidationFailed, {
          category: currentCredentialUnderFetch?.category?.name,
          name: currentCredentialUnderFetch?.name,
          status: 'ABORT',
          ...getTxOrgId()
        });
        return null;
      }
      extraDataToSend = customUiDataToSubmitRef?.current?.reduce((acc, item) => {
        acc = { ...acc, ...item };
        return acc;
      }, {});
    }


    const submitVerification = async (bodyToSend = {
      'data': {
        'transactionId': searchedQuery?.txnId,
        'orgId': searchedQuery?.orgId
      },
      'transactionId': searchedQuery?.txnId,
      'orgId': searchedQuery?.orgId,
      'verificationName': currentCredentialUnderFetch.key,
    }, tempFieldsArray) => {
      mixpanel.track(trackingEvents.verification.events.triggerSubmitVerification, {
        'category': currentCredentialUnderFetch?.category?.name,
        'name': currentCredentialUnderFetch?.name,
        'verificationName': currentCredentialUnderFetch.key,
        'status': 'START',
        'dataToSubmit': tempFieldsArray,
        ...getTxOrgId()
      });
      console.log('bodyToSend:', bodyToSend);
      if (bodyToSend.data) {
        bodyToSend.data = {
          ...bodyToSend.data,
          ...address
        };
      }
      let msheets = null;
      const documents = [];
      const keysToLookForDocuments = ['Pan Card', '10th Marksheet', '12th Marksheet', 'salaryslip', 'appointmentletter', 'experienceletter'];
      // const msheets = body?.documentList?.filter((zz) => zz.label === 'Marksheet');

      console.log('tempFieldsArray', tempFieldsArray);
      // if marksheets are required
      if (formInfo?.[currentCredentialUnderFetch.key]?.marksheetRequired) {
        const ar = [];
        // visit each record and upload its marksheet
        for await (const zz of tempFieldsArray) {
          let link = null;
          if (typeof zz?.Marksheet2 === 'string' && zz?.Marksheet2?.length > 0) {
            link = zz?.Marksheet2;
          } else {
            link = await uploadFile(zz?.Marksheet2, {
              folderName: `${currentCredentialUnderFetch?.key}-marksheet`,
              // imageHasDocument: true,
            });
          }
          if (link) {
            ar.push({ label: 'Marksheet', url: link });
          } else {
            mixpanel.track(trackingEvents.verification.events.triggerSubmitVerification, {
              category: currentCredentialUnderFetch?.category?.name,
              name: currentCredentialUnderFetch?.name,
              status: 'ABORT',
              reason: `Unable to upload marksheet file directly:: link:: ${link} :: typeof :: ${typeof zz?.Marksheet2}`,
              link,
              ...getTxOrgId()
            });
          }
        }

        // if (typeof state?.subTypes === 'object') {
        //   const ar2 = Object.keys(state?.subTypes)?.map((zz) => ({
        //     label: 'Marksheet',
        //     url: state?.subTypes[zz]
        //   }));
        //   ar = [...ar, ...ar2];
        // }
        // if a new form was added over previously submitted forms, subTypes will be there, so put them in msheets array otherwise upload the file
        if (typeof subTypes === 'object') {
          for await (const zz of Object.keys(subTypes).filter((x) => x !== edit?.uid)) {
            let link = null;
            if (typeof subTypes[zz] === 'string') {
              link = subTypes[zz];
            } else {
              link = await uploadFile(subTypes[zz], {

                folderName: `${currentCredentialUnderFetch?.key}-marksheet`,
                // imageHasDocument: true,
              });
            }
            if (link) {
              ar.push({ label: 'Marksheet', url: link });
            } else {
              mixpanel.track(trackingEvents.verification.events.triggerSubmitVerification, {
                category: currentCredentialUnderFetch?.category?.name,
                name: currentCredentialUnderFetch?.name,
                status: 'ABORT',
                reason: `Unable to upload marksheet file using subTypes:: link:: ${link}  :: typeof :: ${typeof subTypes[zz]}`,
                link,
                ...getTxOrgId()
              });
              window.snackbar('error', 'Unable to upload file');
              return null;
            }
          }
        }
        msheets = ar;
      }
      // if its a bgv, look for documents containing specific fieldnames in each recordsSet, and upload them
      if (currentCredentialUnderFetch.key.includes('bgv-')) {
        for await (const zz of tempFieldsArray) {
          for await (const key of keysToLookForDocuments) {
            if (zz?.[key]) {
              let link = null;
              // check if document was already uploaded(in case of oldDataFound ones)
              if (typeof zz?.[key] === 'string' && zz?.[key].length > 0) {
                link = zz?.[key];
              } else {
                // upload the file, save the url
                link = await uploadFile(zz?.[key], {
                  folderName: currentCredentialUnderFetch?.key
                });
              }
              // check if link now exists
              if (link) {
                documents.push({ label: key, url: link });
              } else {
                mixpanel.track(trackingEvents.verification.events.triggerSubmitVerification, {
                  category: currentCredentialUnderFetch?.category?.name,
                  name: currentCredentialUnderFetch?.name,
                  status: 'ABORT',
                  reason: `Unable to upload docs, while looking for documents containing specific fieldnames in each recordsSet:: link:: ${link} :: typeof :: ${typeof zz?.[key]}`,
                  link,
                  ...getTxOrgId()
                });
                window.snackbar('error', 'Unable to upload file');
                return null;
              }
            }
          }
        }
      }
      mixpanel.track(trackingEvents.verification.events.triggerSubmitVerification, {
        category: currentCredentialUnderFetch?.category?.name,
        name: currentCredentialUnderFetch?.name,
        status: 'CHECKPOINT',
        reason: 'sending data for verification',
        ...getTxOrgId()
      });
      return axiosInstanceWithoutBaseUrl(`${c[page]?.api?.endpointBaseUrl ?
        apiEndpoints[c[page]?.api?.endpointBaseUrl](currentCredentialUnderFetch.key) :
        c[page]?.api?.endpoint}`, {
        method: c[page].api.method.toUpperCase(),
        data: {
          ...bodyToSend,
          data: {
            ...bodyToSend?.data,
            document: documents
          },
          ...(currentCredentialUnderFetch.key.includes('bgv') ?// if its a bgv
            {
              subTypeList: [...newSubTypes, // add any new subTypes
                ...Object.keys(subTypes)?.filter((key) => edit?.uid !== key)]// add any previously added subTypes
            } : {}),
          ...(formInfo?.[currentCredentialUnderFetch.key]?.marksheetRequired ? {// if marksheets are required
            'marksheetList': msheets// upload marksheets
          } : null),
          document: documents// list of documents like degree, pan card
        },
        ...getAxiosHeader({})
      }).then((res) => {
        if (res.data.code === 0) {
          // no credential is to be made, so redirect to main form screen
          if (data.disableBuildCredential) {
            setLoading(false);
            mixpanel.track(trackingEvents.verification.events.triggerSubmitVerification, {
              category: currentCredentialUnderFetch?.category?.name,
              name: currentCredentialUnderFetch?.name,
              status: 'SUCCESS',
              reason: 'this is a manual verification, so redirect to main form screen',
              ...getTxOrgId()
            });
            return navigate(searchedQuery?.link, { state: { moveToNextVerification: true } });
          }
        } else {
          mixpanel.track(trackingEvents.verification.events.triggerSubmitVerification, {
            category: currentCredentialUnderFetch?.category?.name,
            name: currentCredentialUnderFetch?.name,
            status: 'ABORT',
            reason: res?.data.message,
            apiResponse: res?.response?.data,
            ...getTxOrgId()
          });
          window.snackbar('error', res?.data.message);
        }
        return res;
      }).catch((e) => {
        console.log('error2', e);
        mixpanel.track(trackingEvents.verification.events.triggerSubmitVerification, {
          category: currentCredentialUnderFetch?.category?.name,
          name: currentCredentialUnderFetch?.name,
          status: 'ABORT',
          reason: e,
          apiResponse: e,
          ...getTxOrgId()
        });
        window.snackbar('error', e?.response?.data?.message ?? e.message);
      });
    };

    // for cases like Global Database Checks, Address Checks, no form is required, so directly trigger submit verification
    if (recordsSet.length <= 0 && !edit) {
      await submitVerification({
        'data': {
          'transactionId': searchedQuery?.txnId,
          'orgId': searchedQuery?.orgId
        },
        'transactionId': searchedQuery?.txnId,
        'orgId': searchedQuery?.orgId,
        'verificationName': currentCredentialUnderFetch?.key,
      }, []).finally((res) => {
        setLoading(false);
        return res;
      });
      return null;
    }


    try {
      for await (const fields of recordsSet) {
        console.log('recordsSet, fields:', recordsSet, fields);

        if (!markRecordsAsDone[currentFormCount]) {
          const tempFields = { ...(trimFields(fields)) };
          delete tempFields.privateData2;
          console.log('loop-check1:fields', currentFormCount);
          // run validations
          const ers = {};
          inputs
          // ?.filter((z) => z.inputType !== 'file')
              ?.forEach((z) => {
                if ((fields[z.fieldName] !== undefined || (z?.validation && (z?.required != undefined ? z?.required : true))) && z?.validation && z?.validation?.fn &&
                !(eval(z?.validation?.fn)(tempFields[z.fieldName]))) {
                  ers[z.fieldName] = {
                    message: z.validation?.message
                  };
                  return false;
                } else {
                  return true;
                }
              });
          tempErr[currentFormCount] = ers;
          // setError(tempErr);
          console.log('tempErr', tempErr);
          // if (Object.keys(tempErr[currentFormCount])?.length <= 0) {

          // files management: iterate through inputs, check if there is a file input
          let fileCount = 0;
          for await (const z of inputs) {
            console.log('loop-check2:', z.fieldName);

            if (z.inputType === 'file') {
              if (ers[z.fieldName]) {
                delete ers[z.fieldName];
                tempErr[currentFormCount] = ers;
                // setError(tempErr);
              }
              // if file is not uploaded in this record
              if (!fields[z.fieldName]) {
                // check if it exists in documentList(sent by backend)
                if (tempFields?.documentList?.[fileCount]) {
                  tempFields[z.fieldName] = tempFields?.documentList?.[fileCount];
                  fileCount++;
                }
                // if not, set error message
                if (!tempFields[z.fieldName]) {
                  ers[z.fieldName] = {
                    message: `${z?.validation?.message}`
                  };
                }
                const tempErr = [...error];
                tempErr[currentFormCount] = ers;
                // setError(tempErr);
                setLoading(false);
              } else if (z && z?.fieldName?.length > 0) {
                tempFields.documentList = [];
                let link = '';
                // check if the url exists for this file
                if (typeof tempFields?.[z?.fieldName] === 'string') {
                  link = tempFields?.[z?.fieldName];
                } else {
                  // otherwise save it in uploadFilesHere to upload later
                  if (typeof fields[z.fieldName] !== 'string') {
                    uploadFilesHere.push({ index: currentFormCount, fieldName: z.fieldName });
                  }
                  link = fields[z.fieldName];
                }
                mixpanel.track(trackingEvents.verification.events.fileManagement, {
                  category: currentCredentialUnderFetch?.category?.name,
                  name: currentCredentialUnderFetch?.name,
                  status: 'CHECKPOINT',
                  link,
                  ...getTxOrgId()
                });
                console.log('link', link);
                if (link) {
                  tempFields[z.fieldName] = link;
                } else {
                  setLoading(false);
                  mixpanel.track(trackingEvents.verification.events.fileManagement, {
                    category: currentCredentialUnderFetch?.category?.name,
                    name: currentCredentialUnderFetch?.name,
                    status: 'ABORT',
                    reason: `Unable to build file link :: typeof fields[z.fieldName] :: ${typeof fields[z.fieldName]}`,
                    link,
                    ...getTxOrgId()
                  });
                  throw new Error('');
                }
              }
            }
            // format a value if it needs formatting
            if (z.format) {
              if (z.inputType === 'date') {
                if (dayjs(tempFields[z.fieldName]).isValid()) {
                  tempFields[z.fieldName] = displayTime(tempFields[z.fieldName], z.format);
                }
                else {
                  // const ers = {};
                  ers[z.fieldName] = {
                    message: `Enter a valid date`
                  };
                  const tempErr = [...error];
                  tempErr[currentFormCount] = ers;
                  // setError(tempErr);
                }
              }
            }
          }
          // }
          // if marksheet is required but not uploaded
          if (!edit && formInfo?.[currentCredentialUnderFetch.key]?.marksheetRequired) {
            if (!tempFields.Marksheet2) {
              ers['Marksheet2'] = {
                message: fileInputConfig?.validation?.message
              };
            }
          }
          // save all the error fields and their messages
          tempErr[currentFormCount] = ers;
          console.log('tempFields tempErr:', tempFields, tempErr);
          // save modified validated recordSet
          tempFieldsArray.push(tempFields);
        }

        currentFormCount++;
      }

      setError(tempErr);
      // scroll to the errored field
      console.log('error, tempErr::', error, tempErr);
      let qErrCount = 0;
      for (const eq of tempErr) {
        if (Object.keys(eq)?.length > 0) {
          setLoading(false);

          document.getElementById(`${qErrCount}-${Object.keys(eq)[0]}`)
              ?.scrollIntoView({ behavior: 'smooth' });
          throw new Error('Please fill in valid data');
        }
        qErrCount++;
      }
    } catch (e) {
      console.log(e);
      mixpanel.track(trackingEvents.verification.events.validation, {
        category: currentCredentialUnderFetch?.category?.name,
        name: currentCredentialUnderFetch?.name,
        status: 'ABORT',
        reason: e.message,
        apiResponse: e?.response?.data,
        ...getTxOrgId()
      });
      window.snackbar('error', e.message);
      return null;
    }

    // start uploading files
    for await (const z of uploadFilesHere) {
      const link = await uploadFile(
          tempFieldsArray[z.index][z.fieldName],
          {
            ...(currentCredentialUnderFetch?.key.includes('bgv-') ? {
              folderName: currentCredentialUnderFetch?.key
            } : {}),
          // imageHasDocument: true,
          }
      );

      if (link) {
        tempFieldsArray[z.index][z.fieldName] = link;
      }
      else {
        setLoading(false);
        mixpanel.track(trackingEvents.verification.events.fileManagement, {
          category: currentCredentialUnderFetch?.category?.name,
          name: currentCredentialUnderFetch?.name,
          status: 'ABORT',
          reason: `Unable to upload marksheet file:: link:: ${link}`,
          link,
          ...getTxOrgId()
        });
        window.snackbar('error', 'Unable to upload file');
        return null;
      }
    }
    // reset form count
    currentFormCount = 0;
    let tempMarkAsDone = { ...markRecordsAsDone };
    if (tempFieldsArray?.length !== recordsSet?.length) {
      return null;
    }
    // iterate the modified validated recordsSet, and upload them
    for await (const fields of tempFieldsArray) {
      if (!markRecordsAsDone[currentFormCount]) {
        try {
          const tempFields = { ...fields };

          const bodyMaker = eval(c[page].api.body);
          console.log(bodyMaker);
          const body = bodyMaker({
            ...tempFields,
            ...(c[page].captcha ? {
              id: captchaId,
              captchaId: captchaId,
              captchaText: captchaInput,
            } : {})
          }, formResponses);
          tempFieldsArray[currentFormCount] = { ...body, ...fields };
          if (true) {
            console.log('body:', body);
            const bodyToSend = {
              ...body,
              ...extraDataToSend,
              'data': {
                ...body,
                ...extraDataToSend,
                'transactionId': searchedQuery?.txnId,
                'orgId': searchedQuery?.orgId
              },
              'transactionId': searchedQuery?.txnId,
              'orgId': searchedQuery?.orgId,
              'verificationName': currentCredentialUnderFetch.key,
              'subType': state?.edit?.uid// works for editing ones
            };
            let addedProfileVerificationData = state?.edit?.uid;
            let response = {
              data: {
                code: 0
              }
            };
            // check if this verification gets saved in profile, then upload in profile
            const foundInProfile = !c?.[page]?.showResponseBeforeSubmission && getVerificationFromProfileSync(currentCredentialUnderFetch);
            if ((page === c?.length - 1) && foundInProfile) { // if it is the last page of verification
              addedProfileVerificationData = await addProfileVerificationData(currentCredentialUnderFetch.key, bodyToSend);
              response.data.data = addedProfileVerificationData;
              if (!addedProfileVerificationData) {
                mixpanel.track(trackingEvents.verification.events.addToProfile, {
                  category: currentCredentialUnderFetch?.category?.name,
                  name: currentCredentialUnderFetch?.name,
                  status: 'FAILED',
                  reason: addedProfileVerificationData,
                  ...getTxOrgId()
                });
                throw new Error('');
              }
              // if profile is being set, update the profile, and start verification process, if userInput(basic details) not required
              if (currentCredentialUnderFetch.settingProfile) {
                await updateWalletAndStartFormProcess(!userInput?.metadata, response?.data?.data?.attributes);
              }
              tempMarkAsDone = { ...tempMarkAsDone, [currentFormCount]: true };
              mixpanel.track(trackingEvents.verification.events.addToProfile, {
                category: currentCredentialUnderFetch?.category?.name,
                name: currentCredentialUnderFetch?.name,
                status: 'SUCCESS',
                ...getTxOrgId()
              });
            }
            // save its subType
            newSubTypes.push(addedProfileVerificationData);


            // if api call is required, not editing, if it is the last iteration of records set if multiple submissions are required
            if (c?.[page]?.api?.endpointBaseUrl != 'NO' && (!edit ? true : (data.disableBuildCredential
            )) &&
              (currentCredentialUnderFetch?.data.acceptMultipleSubmissions ?
                ((tempFieldsArray.length - 1 === currentFormCount) && !edit) : true)) {
              response = await submitVerification(bodyToSend, tempFieldsArray);
            } else if (listOfSideProcesses?.[currentCredentialUnderFetch?.key]) {
              // else get its attributes to hit its side processes
              const temp = { ...formResponses };
              temp[page] = {
                response: response.data.data ?? {},
                input: tempFields
              };
              const tempFn = eval(currentCredentialUnderFetch?.data.getAttributes);
              let tempFormResponse = tempFn(temp);
              console.log('tempFormResponse', tempFormResponse);
              tempFormResponse = tempFormResponse?.reduce((acc, atr, i) => {
                acc[atr.name] = atr.value;
                return acc;
              }, {});
              if (listOfSideProcesses?.[currentCredentialUnderFetch?.key]) {
                listOfSideProcesses?.[currentCredentialUnderFetch?.key](tempFormResponse);
              }
              // return navigate(searchedQuery?.link);
            }
            if (response && response?.data?.code === 0) {
              // mark this record as done(submitted)
              tempMarkAsDone = { ...tempMarkAsDone, [currentFormCount]: true };
              // if multiple submissions are allowed,
              if (currentCredentialUnderFetch?.data.acceptMultipleSubmissions) {
                // run continue to the top, get to next record submission
                mixpanel.track(trackingEvents.verification.events.afterTriggerSubmitVerification, {
                  category: currentCredentialUnderFetch?.category?.name,
                  name: currentCredentialUnderFetch?.name,
                  status: 'CHECKPOINT',
                  reason: `Submitted:: ${currentFormCount}`,
                  subType: addedProfileVerificationData,
                  body,
                  ...getTxOrgId()
                });
                if (currentFormCount < recordsSet?.length - 1) {
                  currentFormCount++;// increment the form counter
                  continue;
                } else {
                  setLoading(false);
                  if (edit) {
                    // refreshing the saved data, go to records list page if edit was on
                    const wallet = await getWallet();
                    await getDataFromProfile(wallet);
                    mixpanel.track(trackingEvents.verification.events.editing, {
                      category: currentCredentialUnderFetch?.category?.name,
                      name: currentCredentialUnderFetch?.name,
                      subType: addedProfileVerificationData,
                      status: 'SUCCESS',
                      ...getTxOrgId()
                    });
                    return navigate('/docs', { state: { ...state, edit: false } });
                  } else {
                    // all done, go to form
                    mixpanel.track(trackingEvents.verification.events.allDone, {
                      category: currentCredentialUnderFetch?.category?.name,
                      name: currentCredentialUnderFetch?.name,
                      status: 'SUCCESS',
                      ...getTxOrgId()
                    });
                    return navigate(searchedQuery?.link, { state: { moveToNextVerification: true } });
                  }
                }
              }
              if (data.disableBuildCredential) {
                setLoading(false);
                console.log('searchedQuery', searchedQuery?.link);
                return navigate(searchedQuery?.link, { state: { moveToNextVerification: true } });
              }

              const temp = { ...formResponses };
              temp[page] = {
                response: response.data.data ?? {},
                input: tempFields
              };
              console.log('temp', temp);
              setFormResponses(temp);
              if (page < c?.length - 1) {// save form data submitted till here, move to next page
                setPage(page + 1);
                mixpanel.track(trackingEvents.verification.events.movingToNextPage, {
                  category: currentCredentialUnderFetch?.category?.name,
                  name: currentCredentialUnderFetch?.name,
                  status: 'CHECKPOINT',
                  ...getTxOrgId()
                });
              } else {
                // build attributes
                let bodyForPatch = eval(currentCredentialUnderFetch?.data?.submitToVc);
                bodyForPatch = bodyForPatch(temp);

                console.log('bodyForPatch', bodyForPatch);
                const tempFn = eval(currentCredentialUnderFetch?.data.getAttributes);
                let tempFormResponse = tempFn(temp);
                console.log('tempFormResponse', tempFormResponse);
                tempFormResponse = tempFormResponse?.reduce((acc, atr, i) => {
                  acc[atr.name] = atr.value;
                  return acc;
                }, {});
                // if response is to be displayed before final submission, send to displayInfo, else send to form
                if (!foundInProfile) {
                  mixpanel.track(trackingEvents.verification.events.allDone, {
                    category: currentCredentialUnderFetch?.category?.name,
                    name: currentCredentialUnderFetch?.name,
                    status: 'CHECKPOINT',
                    reason: 'Displaying View Attributes Screen',
                    ...getTxOrgId()
                  });
                  updateStore('docData', tempFormResponse);
                  navigate('/displayInfo', { state: { bodyToSend } });
                } else {
                  mixpanel.track(trackingEvents.verification.events.allDone, {
                    category: currentCredentialUnderFetch?.category?.name,
                    name: currentCredentialUnderFetch?.name,
                    status: 'SUCCESS',
                    ...getTxOrgId()
                  });
                  if (typeof searchedQuery === 'object' && searchedQuery?.link) {
                    navigate(searchedQuery?.link);
                  } else {
                    navigate('/');
                  }
                }
              }
            } else {
              console.log('MarkRecordsAsDone:', markRecordsAsDone, currentFormCount);

              delete tempMarkAsDone[currentFormCount];
            }
          }
        } catch (error) {
          // Handle error here
          setLoading(false);
          window.snackbar('error', error?.response?.data?.message || error?.message);
          console.log(error);
          break;
        }
      }
      setMarkRecordsAsDone(tempMarkAsDone);
      currentFormCount++;
    }
    if (Object.keys(markRecordsAsDone)?.length === recordsSet?.length) {
      setLoading(false);
      mixpanel.track(trackingEvents.verification.events.allDone, {
        category: currentCredentialUnderFetch?.category?.name,
        name: currentCredentialUnderFetch?.name,
        status: 'SUCCESS',
        ...getTxOrgId()
      });
      return navigate(searchedQuery?.link, { state: { moveToNextVerification: true } });
    }

    setLoading(false);
  };

  const miscellaneousUi = <>
    {inputs?.length <= 0 && data?.homeImage ? <Grid container>
      <img
        src={data?.homeImage}
        style={{ width: '60%', margin: '1em auto' }} />
    </Grid> : null}
    {c[page]?.readOnly ? <Grid container>
      <ListDataUI
        info={Object.keys(c[page]?.readOnly).reduce((acc, item) => {
          if (item === 'profile') {
            Object.keys(c[page]?.readOnly?.profile).forEach((key) => {
              if (currentUserData) {
                // if (typeof c[page]?.readOnly?.profile?.[key] === 'string') {
                acc[key] = form?.candidate?.profileDetails?.[c[page]?.readOnly?.profile?.[key]];
                // } else if (c[page]?.readOnly?.profile?.[key]?.component) {
                //   acc[key] = <DataCard
                //     data={currentUserData?.profileDetails?.[c[page]?.readOnly?.profile?.[key]]}
                //     type={c[page]?.readOnly?.profile?.[key].component}
                //     sx={{ margin: 0 }}
                //   />;
                // }
              }
              return acc;
            }, {});
          }
          return acc;
        }, {})}
      // customUi={
      //   getFormattedUiForAnAttribute[currentCredentialUnderFetch.key] ?
      //     getFormattedUiForAnAttribute[currentCredentialUnderFetch.key] :
      //     {}
      // }
      />
    </Grid> : null}


    {(c[page]?.showSpecificForm && c[page]?.showSpecificForm?.length > 0) ? <Grid
      container direction="column"
      sx={{ flexWrap: 'nowrap' }}>
      {c[page]?.showSpecificForm?.map((x, i) => customForms(x, (e) => {
        // customUiDataToSubmitRef.current[i] = e;
      }))}
    </Grid> : null}


    {(data?.showSpecificForm && data?.showSpecificForm?.length > 0) ? <Grid
      container direction="column"
      sx={{ flexWrap: 'nowrap', marginTop: '0px' }}>
      {data?.showSpecificForm?.map((x, i) => customForms(x, (e) => {
        customUiDataToSubmitRef.current[i] = e;
      }, (el) => refForChildFunctionCustomUiTrigger.current[i] = el, {
        loading: loadingInCustomForms,
        setLoading: setLoadingInCustomForms,
        editData: edit ? editData?.data : recordsSetStale[0],
        setSubmitButtonDisabledForParent: setSubmitButtonDisabled
      }))}
    </Grid> : null}

    {c[page].captcha ? <Captcha
      argsForFetcher={currentCredentialUnderFetch?.key}
      captchaId={captchaId} setCaptchaId={setCaptchaId}
      captchaInput={captchaInput} setCaptchaInput={setCaptchaInput} /> : null}
  </>;

  console.log('recordsSet', recordsSet);

  const componentToShowAtTop = <>
    {c[page]?.topInfo && c[page]?.topInfo?.display ?
      <Alert
        severity="info"
        sx={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}
      >
        <MarkdownWrapper>
          {c[page]?.topInfo?.text}
        </MarkdownWrapper>
      </Alert> : null}
  </>;

  const extrasInsideBottomComponent = <>
    {c[page]?.bottomInfo ?
      <Alert
        severity="info"
        sx={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}
      >
        <MarkdownWrapper>
          {c[page]?.bottomInfo}
        </MarkdownWrapper>
      </Alert> : null}
  </>;

  return edit ?
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      sx={{
        padding: '1em',
        borderRadius: '8px',
        margin: '1em 0 0em 0',
        flexWrap: 'nowrap',
      }}>
      {componentToShowAtTop}
      <ComponentToRender
        indexOfRecordSet={0}
        name={data.name}
        data={data}
        inputs={inputs}
        formResponses={formResponses}
        updateField={updateField}
        error={error[0]}
        progress={progress[0]}
        recordsSet={recordsSet[0]}
        setError={setError}
        vConfigModified={formInfo[currentCredentialUnderFetch?.key]}
        edit={editData?.data}
        currentCredentialUnderFetch={currentCredentialUnderFetch} />

      {miscellaneousUi}

      <BottomComponent>
        {extrasInsideBottomComponent}
        <LoadingButton
          variant="contained"
          color="primary"
          size="large"
          disabled={loading || submitButtonDisabled}
          onClick={async () => {
            const body = await submitData();
            setLoading(false);
            console.log('body:Submit:', body);
          }}
          sx={{
            width: '100%',
          }}
          datacy="submit"
        >
          {edit ? 'Save' : 'Submit'}
        </LoadingButton>
      </BottomComponent>
    </Grid> :
    <>
      {componentToShowAtTop}
      {currentCredentialUnderFetch?.data?.acceptMultipleSubmissions ? <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{
          padding: '1em',
          borderRadius: '8px',
          margin: '1em 0 0em 0',
          flexWrap: 'nowrap',
        }}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          sx={{
            flexWrap: 'nowrap',
            borderRadius: '8px',
            margin: '1em 0 0em 0',
          }}>

          <TransitionGroup
            style={{ width: '100%' }}>
            {recordsSet?.map((record, i) => <Collapse
              sx={{ width: '100%' }}
              key={`record-${i}-${record?.privateData2?.id}`}>
              <MainCard
                sx={{ width: '100%' }}>
                <ComponentToRender
                  indexOfRecordSet={i}
                  name={data.name}
                  inputs={inputs}
                  formResponses={formResponses}
                  updateField={updateField}
                  error={error[i]}
                  progress={progress[i]}
                  recordsSet={record}
                  setError={setError}
                  onRemove={() => {
                    let temp = [...recordsSet];
                    temp = temp.filter((z, qw) => qw !== i);
                    setRecordsSet(temp);
                  }}
                  vConfigModified={formInfo[currentCredentialUnderFetch?.key]}
                  currentCredentialUnderFetch={currentCredentialUnderFetch} />

                {formInfo?.[currentCredentialUnderFetch.key]?.marksheetRequired ? <FileInput
                  input={fileInputConfig}
                  onChange={(a, b) => {
                    updateField('Marksheet2', {
                      target: {
                        value: b
                      }
                    }, i);
                  }}
                  error={error[i]}
                  // progress={progress}
                  data={record}
                  defaultHelperText={fileInputConfig?.helperText || ''}
                  sx={{
                    margin: 'auto',
                    width: '96%'
                  }}
                  disabled={false}
                /> : null}
              </MainCard>

            </Collapse>)}
          </TransitionGroup>
        </Grid>


        {[form?.state].includes(statesEnum.CLOSED) ? null : <Button
          variant="outlined"
          onClick={() => {
            setRecordsSet([...recordsSet, constructFields()]);
            setError([...error, {}]);
            setProgress([...progress, {}]);
          }}>
          Add another
          {' '}
          {currentCredentialUnderFetch?.category?.name}
        </Button>}
        <Toolbar />
      </Grid> : <ComponentToRender
        indexOfRecordSet={0}
        name={data.name}
        data={data}
        inputs={inputs}
        formResponses={formResponses}
        updateField={updateField}
        error={error[0]}
        progress={progress[0]}
        recordsSet={recordsSet[0]}
        setError={setError}
        vConfigModified={formInfo[currentCredentialUnderFetch?.key]}
        currentCredentialUnderFetch={currentCredentialUnderFetch} />}

      {miscellaneousUi}

      {
        (c[page].submitButtonText && ![form?.state].includes(statesEnum.CLOSED) &&
          (form?.[currentCredentialUnderFetch?.key]?.settingProfile ?
          true : (
            currentCredentialUnderFetch?.data?.acceptMultipleSubmissions ?
              true : (
                ![statesEnum.COMPLETED, statesEnum.IN_PROGRESS].includes(formInfo[currentCredentialUnderFetch?.key]?.state)
              )
          )
          )
        ) ?
          <BottomComponent>

            {extrasInsideBottomComponent}
            {formInfo[currentCredentialUnderFetch?.key]?.skipAllowed && !formInfo[currentCredentialUnderFetch?.key].markedAsSkip && (
              formInfo[currentCredentialUnderFetch?.key]?.state === statesEnum.NOT_INITIALIZED ||
              formInfo[currentCredentialUnderFetch?.key]?.state === statesEnum.DATA_SAVED
            ) ? <Button
              variant="text"
              color="brand"
              loading={loadingSkip}
              sx={{ width: '30%' }}
              onClick={async () => {
                skip();
              }}>
              Skip
            </Button> : null}
            {((currentCredentialUnderFetch
                ?.supportedFormList?.length === 1 && currentCredentialUnderFetch
                ?.supportedFormList
                ?.includes('bgv')) || bgvOpted) ? <Typography
                  variant="body1" sx={{
                    fontWeight: 500, margin: '0em 1em',
                    textAlign: 'left'
                  }}
                  color="grey">
                  {/* On submission, your data will be sent for verification. You will be notified once your document is processed. */}
                </Typography> : (currentCredentialUnderFetch
                    ?.supportedFormList
                    ?.includes('bgv') ? <Typography
                      variant="body1"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        margin: '0 1em',
                        marginRight: 'auto',
                      }}>
              Missing anything?
                      <Button
                        variant="text" sx={{ margin: '4px', padding: 0 }}
                        onClick={() => {
                          setBgvOpted(!bgvOpted);
                        }}>
                Upload Document
                      </Button>
                    </Typography> : null)}

            <Grid container sx={{ padding: '0 0 0 2em' }}>
              <FormControlLabel
                control={<Checkbox
                  datacy="checkbox-consent"
                  defaultChecked
                  checked={checked}
                  onClick={() => setChecked(!checked)} sx={{ paddingLeft: 0 }} />}
                label={(<Typography variant="body2" sx={{ textAlign: 'left' }}>
                  {/* {form[currentCredentialUnderFetch?.key] ? c[page].submitButtonCheck : `I give my consent to NSDC Trust for conducting verification of ${currentCredentialUnderFetch?.name} document and retain the details for internal purposes.`} */}
                  {formInfo[currentCredentialUnderFetch?.key]?.consentMessage ?
                    formInfo[currentCredentialUnderFetch?.key]?.consentMessage :
                    (
                      form?.list?.[currentCredentialUnderFetch?.key]?.consentMessage ||
                      form?.list?.[currentCredentialUnderFetch?.key].metadata.consent.default)}
                </Typography>)} />
            </Grid>
            <LoadingButton
              datacy="submit"
              loading={loading}
              variant="contained" color="primary"
              sx={{ width: '96%', margin: '1em auto' }}
              onClick={async () => {
                setLoading(true);
                await submitData();
                setLoading(false);
              }}
              disabled={loadingInCustomForms || submitButtonDisabled || c[page].captcha ? (!Boolean(captchaInput?.length) || !checked) : !checked}>
              {c[page].submitButtonText}
            </LoadingButton>

          </BottomComponent> :
          null
      }


    </>;
}


const ComponentToRender = ({ data, indexOfRecordSet, name, inputs, formResponses,
  updateField, setError, error, progress, recordsSet, onRemove = null, vConfigModified, edit = {},
  currentCredentialUnderFetch }) => (
  <>
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      sx={{
        padding: '1em',
        borderRadius: '8px',
        margin: '1em 0 0em 0',
        // maxHeight: '100vh',
        width: '100%',
        position: 'relative',
        flexWrap: 'nowrap',
      }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="space-between"
        sx={{ width: '100%', marginBottom: '1em' }}>
        {onRemove ? <IconButton
          sx={{ position: 'absolute', top: '0px', right: '0.5em' }}
          id="close-form-card"
          onClick={() => {
            onRemove();
          }}>
          <CancelOutlined />
        </IconButton> : null}
        {name ? <Typography
          variant="h6" sx={{
            margin: '0em 0',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}>
          {name}
          {data?.nameLogo ? <img
            src={data?.nameLogo}
            style={{ height: '2rem', margin: 'auto 2px' }} /> : null}

        </Typography> : null}
      </Grid>
      {inputs?.map((input, j) => checkVisibility(vConfigModified, input?.visibility) ? <Grid
        key={`${input.fieldName}-${j}`}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          paddingTop: '1em',
        }}>
        {/* <Typography
          variant="body1" sx={{
            fontWeight: 500, margin: '0em 0'
          }}>
          {input.label}
        </Typography> */}

        {input.description ? <Typography
          variant="body1" sx={{
            fontWeight: 500, margin: '1em 0',
            color: '#888888'
          }}>
          {eval(input.description)(formResponses)}
        </Typography> : null}
        <ConstructFormInputs
          input={input}
          j={j}
          indexOfRecordSet={indexOfRecordSet}
          updateField={updateField}
          setError={setError}
          error={error}
          progress={progress}
          recordsSet={recordsSet}
          edit={edit}
          currentCredentialUnderFetch={currentCredentialUnderFetch}
        />
      </Grid> : null)
      }
    </Grid>

  </>);

const checkVisibility = (args, fn = null) => {
  // console.log('args', args);
  if (!fn) {
    return true;
  } else {
    fn = eval(fn);
    const v = fn(args);
    return v;
  }
};
