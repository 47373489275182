/* eslint-disable new-cap */

import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, Grid, List } from '@mui/material';
import { animate } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SimpleBackground from '../../Components/Background/Simple';
import ListDataUI from '../../Components/ListDataUI';
import BottomComponent from '../../Components/UI/bottomComponent';
import { addProfileVerificationData, getAttributesCall, getAxiosHeader, getVerificationFromProfileSync, submitToForm, updateWalletAndStartFormProcess } from '../../Config/api';
import { axiosInstanceWithoutBaseUrl } from '../../Config/axios';
import { apiEndpoints } from '../../Config/routes';
import { UIStore, patchStore, updateStore } from '../../Config/store';
import { mixpanel, trackingEvents } from '../../Monitoring/Mixpanel';
import { getCategories, getFormattedUiForAnAttribute, ignoreKeysToDisplayCommon, statesEnum } from '../../Utils/enums';
import { listOfSideProcesses } from '../DocRequest/sideProcesses';

const ignoreKeys = ignoreKeysToDisplayCommon;
export default function DisplayInfo() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const trentialId = UIStore.useState((s) => s.trentialId);
  const currentWorkingStepperStep = UIStore.useState((s) => s.currentWorkingStepperStep);
  const [loadingContinue, setLoadingContinue] = useState(false);
  const [loadWhileFetchingAttributes, setLoadWhileFetchingAttributes] = useState(false);
  const [uris, setUris] = useState(false);
  const [bodyThatWasSent, setBodyThatWasSent] = useState({});
  const [currentCredentialUnderFetch, setCurrentCredentialUnderFetch] = useState({});
  const form = UIStore.useState((s) => s.form);
  const searchedQuery = UIStore.useState((s) => s.searchedQuery);
  const formInfo = UIStore.useState((s) => s.formInfo);
  const userInput = UIStore.useState((s) => s.userInput);
  const [editableFields, setEditableFields] = useState({ 'Care Of': '', 'Education Level': '' });
  const [errors, setErrors] = useState({});

  const docData = UIStore.useState((s) => s.docData) ?? {};
  const statusIconRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = React.useState(0);

  console.info(docData, 'docccccc', currentCredentialUnderFetch, 'curr');

  const formattedDocData = docData;

  useEffect(() => {
    if (!currentCredentialUnderFetch?.key) {
      // navigate((typeof searchedQuery === 'object' && searchedQuery?.link) ? searchedQuery?.link : '/');
    }
  }, [searchedQuery, currentCredentialUnderFetch]);

  React.useEffect(() => {
    let timer = null;
    if (loading) {
      let prev = 0;
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 96) {
            clearInterval(timer);
            return 100;
          }
          const diff = Math.random() * 10;
          const p1 = Math.floor(prev / 25);
          const p2 = Math.floor(Math.min(oldProgress + diff, 100) / 25);
          prev = Math.min(oldProgress + diff, 100);
          console.log('p1 != prev', p1, prev);
          if (p1 !== p2) {
            // animate(statusTextRef.current, { opacity: [0, 1], scale: [0, 1] }, { duration: 0.5 })
            animate(statusIconRef.current, { opacity: [0, 1], scale: [0, 1] }, { duration: 0.4 });
          }
          return Math.min(oldProgress + diff, 100);
        });
      }, 200);
    }
    return () => {
      clearInterval(timer);
    };
  }, [loading]);


  useEffect(() => {
    setCurrentCredentialUnderFetch(form?.list?.[currentWorkingStepperStep] ?? {});
  }, [currentWorkingStepperStep, form]);

  useEffect(() => {
    if (state?.showLoading) {
      startLoadingProcess();
    } else {
      setProgress(100);
    }
  }, []);

  const digilockerFetchAttributes = async (uri1) => {
    console.log('uri1', uri1);
    setLoadWhileFetchingAttributes(true);
    setCurrentCredentialUnderFetch(form.list?.[uri1]);

    const bodyToSend = {
      data: {
        'digiLocker': true,
        'token': state.digilockerToken,
        'uri': state.uris[uri1].uri,
        'transactionId': searchedQuery?.txnId,
        'orgId': searchedQuery?.orgId
      }
    };
    setBodyThatWasSent(bodyToSend);
    await getAttributesCall(form.list?.[uri1].key, bodyToSend).then((res) => {
      if (res) {
        const attributes = res?.attributes;

        const obj = {};
        if (attributes) {
          attributes?.forEach((x) => {
            obj[x.name] = x.value;
          });

          updateStore('docData', {
            ...obj
          });

          updateStore('selectedCredentialAttributes', attributes);
          // navigate('/displayInfo', { state: { showLoading: false } });
        }
      }
    });
    setLoadWhileFetchingAttributes(false);
  };
  useEffect(() => {
    if (state?.uris && Object.keys(state?.uris)?.length > 0) {
      const first = Object.keys(state?.uris)[0];
      setUris(state.uris);
      digilockerFetchAttributes(first);
    } else {
      setLoadWhileFetchingAttributes(false);
    }
  }, []);

  const startLoadingProcess = async () => {
    mixpanel.track(trackingEvents.form.events.select.verificationStarted, {
      category: currentCredentialUnderFetch?.category?.name,
      name: currentCredentialUnderFetch?.name,
      status: 'IN-PROGRESS'
    });
    setLoadWhileFetchingAttributes(true);
    if (listOfSideProcesses?.[currentCredentialUnderFetch?.key]) {
      listOfSideProcesses?.[currentCredentialUnderFetch?.key](docData);
    }

    if (currentCredentialUnderFetch.settingProfile) {
      if (state.digilockerToken) {
        const resForDigilocker = await addProfileVerificationData(currentCredentialUnderFetch.key, {
          'digiLocker': true,
          'verificationName': currentCredentialUnderFetch.key,
          'token': state.digilockerToken,
          'uri': state.uris[currentCredentialUnderFetch.key].uri,
          // 'transactionId': searchedQuery?.txnId,
          // 'orgId': searchedQuery?.orgId
        });
        await updateWalletAndStartFormProcess(!userInput?.metadata);
      } else {
        const resForDigilocker = await addProfileVerificationData(currentCredentialUnderFetch.key, {
          'digiLocker': false,
          'verificationName': currentCredentialUnderFetch.key,
          ...docData,
          ...state?.bodyToSend
          // 'transactionId': searchedQuery?.txnId,
          // 'orgId': searchedQuery?.orgId
        });
        await updateWalletAndStartFormProcess(!userInput?.metadata);
      }
      console.log('searchedQuery: 22', searchedQuery);
      if (searchedQuery?.link) {
        navigate(searchedQuery?.link);
      }
      else {
        navigate('/');
      }
      return null;
    }
    const found = getVerificationFromProfileSync(currentCredentialUnderFetch);
    console.log('found:', found);
    if (!found) {
      await submitToForm(
          searchedQuery?.txnId, currentCredentialUnderFetch?.key,
          (state?.bodyToSend ?? bodyThatWasSent ?? {}),
          currentCredentialUnderFetch?.credentialDefinitionId
          // {
          //   key: currentCredentialUnderFetch?.key,
          //   invitationUrl: docData?.invitationUrl
          // }
      ).then((res) => {
        console.log('res==', res, uris);
        if (res) {
          patchStore('credentialsTriggered', { [currentCredentialUnderFetch?.key]: true }, searchedQuery?.txnId);
          patchStore('infoSubmitted', { [currentCredentialUnderFetch?.key]: docData });
          updateStore('docData', {});
          console.log('res==', res, uris);
          const x = uris ? { ...uris } : {};
          delete x[currentCredentialUnderFetch?.key];
          setUris(x);
          mixpanel.track(trackingEvents.form.events.select.verificationSubmitted, {
            category: currentCredentialUnderFetch?.category?.name,
            name: currentCredentialUnderFetch?.name,
            status: 'SUCCESS'
          });
          if (!x || Object.keys(x)?.length <= 0) {
            setTimeout(() => {
              navigate(searchedQuery?.link, { state: { moveToNextVerification: true } });
            }, 1000);
          } else {
            const first = Object.keys(x)[0];
            digilockerFetchAttributes(first);
          }
        } else {
          navigate(searchedQuery?.link);
          mixpanel.track(trackingEvents.form.events.select.verificationStarted, {
            category: currentCredentialUnderFetch?.category?.name,
            name: currentCredentialUnderFetch?.name,
            status: 'FAILED'
          });
        }
      }).catch((e) => {
        mixpanel.track(trackingEvents.form.events.select.verificationStarted, {
          category: currentCredentialUnderFetch?.category?.name,
          name: currentCredentialUnderFetch?.name,
          status: 'FAILED'
        });
        setLoadWhileFetchingAttributes(false);
      });
    } else {
      await addProfileVerificationData(
          currentCredentialUnderFetch.key,
          {
            ...(docData ? docData : {}),
            ...(state?.bodyToSend ? state?.bodyToSend : {}),
            ...(bodyThatWasSent ? bodyThatWasSent : {})
          }
      ).then(async (res) => {
        if (res) {
          if (currentCredentialUnderFetch?.key.includes('bgv-')) {
            await axiosInstanceWithoutBaseUrl(`${apiEndpoints?.['BGV'](currentCredentialUnderFetch.key)}`, {
              method: 'POST',
              data: {
                ...(state?.bodyToSend ?? bodyThatWasSent ?? {})
              },
              ...getAxiosHeader({})
            });
          }
          mixpanel.track(trackingEvents.form.events.select.verificationSubmitted, {
            category: currentCredentialUnderFetch?.category?.name,
            name: currentCredentialUnderFetch?.name,
            status: 'SUCCESS'
          });
          setTimeout(() => {
            navigate(searchedQuery?.link, { state: { moveToNextVerification: true } });
          }, 1000);
        }
      });
    }
    // setLoading(false);

    // if (!docData.dontUploadDoc) {
    //   setProgress(0);
    //   return putVcInWallet(docData.docVal)
    //       .then((x) => {
    //         if (x) {
    //           setProgress(100);
    //         } else {
    //           navigate(-1);
    //         }
    //       });
    // } else if (docData.dontUploadDoc === 'oob') {
    //   if (docData.invitationUrl && !state?.showLoading) {
    //     setProgress(0);
    //     await receieveOob(docData.invitationUrl).then((x) => {
    //       if (x) {
    //         setProgress(100);
    //       } else {
    //         navigate(-1);
    //       }
    //     });
    //   }
    // }
  };

  return <SimpleBackground
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'nowrap',
      height: '100%',
      width: '100%',
      padding: '0',
      margin: 0
    }}
    title={state?.title ?
      state?.title :
      (state?.onlyShow ? currentCredentialUnderFetch?.name :
        getCategories(currentCredentialUnderFetch?.category?.name)?.subtitle)}>
    {(loadWhileFetchingAttributes) ? <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{ padding: '1em' }}>
      <CircularProgress sx={{ margin: 'auto', height: '100%' }} />
    </Grid> : (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          padding: '1em 0', height: '100%',
          flexWrap: 'nowrap'
        }}>
        {/* <Typography
        variant="body2"
        sx={{
          margin: '1em',
        }}>
        {currentCredentialUnderFetch?.name}
        {' '}
        details that will be submitted
      </Typography>
      <ListItem
        sx={{
          margin: '0em auto',
          cursor: 'pointer',
          bgcolor: '#ECF5FF',
          width: '90%'
        }}>
        <ListItemAvatar sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Avatar sx={{
            border: '1px solid #E7EDF6',
            backgroundColor: '#ffffff',
            margin: 'auto 0.6em auto 0em',
            height: '60px',
            width: '60px', padding: '4px'
          }}>
            <img src={currentCredentialUnderFetch?.icon} style={{ height: '32px' }} alt="logo" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={currentCredentialUnderFetch?.name}
          secondary={(<span>
            {currentCredentialUnderFetch?.orgName}
          </span>)}
          primaryTypographyProps={{
            sx: {
              fontWeight: 'bold',
            }
          }}
          secondaryTypographyProps={{ sx: { margin: '0em', padding: '0em' } }}
        />
      </ListItem> */}
        {/* {((docData.photo ?? docData.Photo)) ?
          <img src={getImageData((formattedDocData.photo ?? formattedDocData.Photo))} alt="doc-img" style={{ width: '50%', margin: '1em' }} /> : null} */}
        <List dense style={{ width: '100%' }}>
          <ListDataUI
            info={formattedDocData}
            setupStates={{
              editableFields,
              setEditableFields,
              errors,
              setErrors
            }}
            currentInfo={{
              name: currentCredentialUnderFetch?.key,
              orgId: searchedQuery?.orgId,
              transactionId: searchedQuery?.txnId
            }}
            customUi={
              getFormattedUiForAnAttribute[currentCredentialUnderFetch.key] ?
                getFormattedUiForAnAttribute[currentCredentialUnderFetch.key] :
                {}
            } />

          {![form?.state].includes(statesEnum.CLOSED) &&
           currentCredentialUnderFetch?.data?.acceptMultipleSubmissions &&
            currentCredentialUnderFetch?.key?.includes('bgv-') ?
            <Button
              variant="outlined"
              sx={{ margin: '1em 0' }}
              onClick={() => {
                navigate('/docs', {
                  openThisFlow: true,
                  state: {
                    subTypes: docData?.data?.reduce((acc, x) => {
                      acc[x.subType] = x.request.data.documentList?.find(((x) => x.label === 'Marksheet'))?.url;
                      return acc;
                    }, {})
                  }
                });
              }}>
              Add More
            </Button> : null}
          {/* {(currentCredentialUnderFetch.key?.includes('bgv-') ?
            <Button
              variant="contained" color="primary"
              sx={{ width: '96%', margin: 'auto' }}
              onClick={async () => {
                navigate(-1);
              }}>
              {currentCredentialUnderFetch?.data?.acceptMultipleSubmissions?''}
            </Button> : null)} */}
          {/* {
            Object.keys(formattedDocData)?.map((item, index) => (!ignoreKeys.includes(item) && formattedDocData[item]) ?
            <DataDisplay data={formattedDocData[item]} />
            <ListItem
              divider
              key={`${item}-${index}`}>
              <ListItemText
                primary={Array.isArray(formattedDocData) ? '' : changeToSentenceCase(item)}
                secondary={<DataDisplay data={formattedDocData[item]} />}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 500,
                    margin: 0,
                    color: '#A6A6A6'
                  }
                }}
                secondaryTypographyProps={{
                  sx: {
                    fontSize: '1.2rem',
                    color: '#404052',
                    fontWeight: 'bold',
                    margin: 0
                  }
                }}
              />
            </ListItem> : null)
          } */}
        </List>
        <span style={{ margin: '2em' }}></span>
        <BottomComponent>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: '100%', margin: '0 8px',
            }}>

            {state?.onlyShow ? <Button
              variant="contained" color="primary"
              sx={{ width: '96%', margin: 'auto' }}
              onClick={async () => {
                navigate(-1);
              }}>
              Back
            </Button> : <>
              {state?.showRetry ? <Button
                variant="outlined" color="brand"
                sx={{ width: '45%' }}
                onClick={async () => {
                  navigate(-1);
                }}>
                Retry
              </Button> : null}
              <LoadingButton
                variant="contained" color="brand"
                loading={loadingContinue}
                disabled={loadingContinue}
                sx={{ width: state?.showRetry ? '45%' : '100%' }}
                onClick={async () => {
                  setLoadingContinue(true);
                  // if (credentialLivenessCheckRecord[docData[getIdentifierKey(docData.documentName)]]
                  //     && trentialId?.referenceImageUrl) {

                  //     await startLoadingProcess()
                  //     updateStore("liveness", {
                  //         image: trentialId?.referenceImageUrl
                  //     })
                  // } else {
                  // if (docData?.Photo ?? docData?.photo) {
                  //   if (!trentialId) {
                  //     setLoadingContinue(false);
                  //     navigate('/liveness');
                  //   } else {
                  //     const compareImage = await compareImageSimilarity(
                  //         trentialId.referenceImageUrl,
                  //         docData?.Photo ?? docData?.photo,
                  //         'sourceImage'
                  //     );
                  //     console.log('compareImage', compareImage);

                  //     if (IMAGE_COMPARE_CONFIDENCE_SCORE !== 0 ? (!compareImage?.error && compareImage?.data > IMAGE_COMPARE_CONFIDENCE_SCORE) : true) {
                  //       updateStore('liveness', {
                  //         image: trentialId.referenceImageUrl
                  //       });
                  //       updateStore('credentialLivenessCheckRecord', {
                  //         ...credentialLivenessCheckRecord,
                  //         [docData[getIdentifierKey(docData.documentName)]]: true
                  //       });
                  //       await startLoadingProcess();
                  //       setLoadingContinue(false);
                  //     } else {
                  //       setLoadingContinue(false);
                  //       window.snackbar('error', 'Your face didn\'t match your document, either try liveness check again or use another document');
                  //       navigate('/liveness');
                  //     }
                  //   }
                  // } else {

                  await startLoadingProcess();
                  setLoadingContinue(false);
                  // }

                  // }
                }}>
                Continue
              </LoadingButton>
            </>}
          </Grid>
        </BottomComponent>

      </Grid >
    )}
  </SimpleBackground>;
}
