import { Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Collapse, Grid, Typography } from '@mui/material';
import { countries } from 'countries-list';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTextField from '../../../Components/UI/Input/customTextField';
import { validPincode } from '../../../Config/api';
import { UIStore } from '../../../Config/store';
import { trimFields } from '../../../Helper/helpers';
import { statesEnum } from '../../../Utils/enums';
import DataCard from '../../DocResponse/dataCard';

const disabledFields = [];

const AddressEditable = React.forwardRef((props, ref) => {
  const { fn = () => { }, label, loading, name } = props;
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const currentUserData = UIStore.useState((s) => s.currentUserData);
  const formInfo = UIStore.useState((s) => s.formInfo);
  const form = UIStore.useState((s) => s.form);
  const currentWorkingStepperStep = UIStore.useState((s) => s.currentWorkingStepperStep);
  const currentCredentialUnderFetch = form?.list?.[currentWorkingStepperStep];

  const addressFields = ['line1', 'landmark', 'vtc', 'district', 'state', 'pincode'];
  const [enableEdit, setEnableEdit] = useState(false);
  const [loadingThis, setLoadingThis] = useState(false);
  const [isEditAllowed, setIsEditAllowed] = useState(false);
  const [disableDoneButton, setDisableDoneButton] = useState(false);

  const [editedState, setEditedState] = useState({});

  const [info, setInfo] = useState(locationState?.preFilled ? locationState?.preFilled : {
    address: {
      pincode: '',
      line1: '',
      state: '',
      district: '',
      country: 'India'
    }
  });

  const address = [
    {
      name: 'pincode',
      label: 'PIN Code',
      required: true,
      type: 'number',
      width: 12
    },
    {
      name: 'line1',
      label: 'Line 1',
      required: true,
      type: 'text',
      width: 12
    },
    {
      name: 'landmark',
      label: 'Landmark',
      required: false,
      type: 'text',
      width: 12
    },
    {
      name: 'vtc',
      label: 'Village/Town/City',
      required: true,
      type: 'text',
      width: 12
    },
    {
      name: 'state',
      label: 'State',
      required: true,
      type: 'text',
      disabled: true,
      width: 6
    },
    {
      name: 'district',
      label: 'District',
      required: true,
      type: 'text',
      disabled: true,
      width: true
    }
  ];

  const filterInfo = (data) => {
    return [...address, {
      name: 'country'
    }].reduce((acc, item) => {
      acc[item.name] = data[item.name];
      return acc;
    }, {});
  };


  const [errors, setErrors] = useState({
    address: {
      'pincode': '',
      'line1': '',
      'landmark': '',
      'vtc': '',
      'state': '',
      'district': '',
      'country': 'India'
    },
  });

  const validPincodeDebounced = useCallback(debounce(async (value, type) => {
    try {
      loading?.setLoading(true);
      setLoadingThis(true);
      const result = await validPincode(value);
      if (result) {
        setEditedState((prev) => ({
          ...prev, [type]: {
            ...prev[type],
            pincode: value,
            state: result?.stateName,
            district: result?.district,
            city: result?.district,
          }
        }));
      }

      loading?.setLoading(false);
      setLoadingThis(false);
    } catch (e) {
      console.log('Error', e);
      loading?.setLoading(false);
      setLoadingThis(false);
    }
  }, 1000), []);


  const [agree, setAgree] = useState(false);

  const handleChange = async (name, value, type) => {
    if (type == 'info') {
      setEditedState((prev) => ({ ...prev, [name]: value }));
      if (errors[name]) deleteFromErrors(errors, setErrors, name);
    } else if (type == 'address') {
      setEditedState((prev) => ({ ...prev, address: { ...prev.address, [name]: value } }));
      if (errors['address'][name]) delete errors.address[name];
      if (name == 'pincode') {
        handlePinChange(value, type);
        delete errors.address['state'];
        delete errors.address['district'];
      }
    }
    else {
      setEditedState((prev) => ({ ...prev, address: { ...prev.address, [name]: value } }));
      if (errors['address'][name]) delete errors.address[name];
      if (name == 'pincode') {
        handlePinChange(value, type);
        delete errors.address['state'];
        delete errors.address['district'];
      }
    }
  };

  const handlePinChange = async (value, type) => {
    if (value?.length < 6) {
      setEditedState((prev) => ({
        ...prev, [type]: {
          ...prev[type],
          pincode: value,
          state: '',
          district: ''
        }
      }));
    }

    if (value?.length == 6) {
      loading?.setLoading(true);
      setLoadingThis(true);
      await validPincodeDebounced(value, type);
    }
  };

  const handleSubmit = async () => {
    try {
      const tempErrors = { address: {} };
      let wasntTriggeredOnce = true;
      const editedStateTrimmed = trimFields(editedState);
      address.forEach((field) => {
        const found = address?.find((x) => x?.name === field?.name);
        if (editedStateTrimmed?.['address']?.[field?.name] === '' && found?.required) {
          tempErrors['address'][field?.name] = `${found?.label} is empty`;
          if (wasntTriggeredOnce) {
            wasntTriggeredOnce = false;
            document.getElementById(`address-${field?.name}`)?.scrollIntoView();
          }
        }
      });

      if (!editedStateTrimmed?.address?.pincode || editedStateTrimmed?.address?.pincode?.length !== 6 || isNaN(editedStateTrimmed?.address?.pincode)) {
        tempErrors['address']['pincode'] = 'Invalid pincode';
        if (wasntTriggeredOnce) {
          wasntTriggeredOnce = false;
          document.getElementById(`address-pincode`)?.scrollIntoView();
        }
      }


      if (Object.keys(tempErrors?.address)?.length > 0) {
        setErrors(tempErrors);
        setEnableEdit(true);
        throw new Error('Errors found');
      }

      setEnableEdit(false);
      fn({
        ...editedStateTrimmed?.address
      });

      setInfo(editedStateTrimmed);
      // await updateWallet({ ...editedState })
      //     .then((res) => {
      //       console.info('address','here');
      //       if (res) {
      //         setEnableEdit(false);
      //         fn({
      //           ...editedState?.address
      //         });
      //         setInfo(editedState);
      //       }
      //     });
      // fn(editedState.address);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const fetchInfo = async () => {
    if (loading?.editData?.[name] && Object.keys(loading?.editData?.[name]).length > 0) {
      let temp = {};
      if (loading?.editData?.[name]) {
        fn(loading?.editData?.[name]);
        temp = {
          ...info, address: loading?.editData?.[name]

        };
      }
      temp.address.pincode = isNaN(temp?.address?.pincode) ? '' : temp?.address?.pincode;
      setInfo(temp);
      setEditedState(temp);
    } else {
      const mobileCc = currentUserData?.mobile?.split('-')[0];
      const code = Object.keys(countries).find((x) => {
        if (`+${countries[x]?.phone?.[0]}` === mobileCc) {
          return true;
        } else {
          return false;
        }
      });
      fn({
        ...currentUserData?.profileDetails?.address,
        country: countries[code]?.name,
        ...loading?.editData
      });
      const temp = {
        ...info, address: {
          ...currentUserData?.profileDetails?.address,
          country: countries[code]?.name,
          ...loading?.editData
        }

      };
      temp.address.pincode = isNaN(temp?.address?.pincode) ? '' : temp?.address?.pincode;
      setInfo(temp);
      setEditedState(temp);
      // fn(temp.permanentAddress);
    }
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      return handleSubmit();
    }
  }));

  useEffect(() => {
    fetchInfo();
    if (![form?.state, formInfo[currentCredentialUnderFetch?.key]?.state].includes(statesEnum.CLOSED)) {
      setIsEditAllowed(form?.specificForms?.[name]
          .every((n) => [statesEnum.NOT_INITIALIZED, statesEnum.DATA_SAVED]?.includes(formInfo[n]?.state)));
    } else {
      setIsEditAllowed(false);
    }
  }, [loading?.editData]);

  useEffect(() => {
    const isAnyRequiredFieldEmpty = address?.some((x) => x.required ? !editedState?.['address']?.[x?.name] : false);
    setDisableDoneButton(isAnyRequiredFieldEmpty);
    loading.setSubmitButtonDisabledForParent(isAnyRequiredFieldEmpty);
  }, [editedState]);

  console.log('addressEditable', loading, name, info);

  // return <></>;
  return (
    <Grid container sx={{ padding: '1em 1.6em' }}>

      <Box sx={{ width: '100%', display: 'flex' }} mb={1}>
        <Typography
          variant="h6" sx={{
            margin: 0,
            fontWeight: 600,
            textAlign: 'left',
            fontSize: '1.3rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}>
          {label || 'Address'}
          {(isEditAllowed && !enableEdit) ? <Button
            id="edit-address"
            variant="text"
            onClick={() => {
              setEnableEdit(!enableEdit);
              setEditedState(info);
            }}
            sx={{ marginLeft: 'auto', width: 'fit-content' }}>
            <Edit sx={{ fontSize: 14 }} />
            &nbsp;
            Edit
          </Button> : null}
        </Typography>
      </Box>
      <Collapse in={!enableEdit} sx={{ width: '100%' }}>
        <Grid container>
          <DataCard
            data={address?.reduce((acc, item, j) => {
              acc[item?.name] = info?.address?.[item?.name];
              return acc;
            }, {})}
            type="address"
            sx={{ margin: 0 }}
          />
        </Grid>
      </Collapse>

      {enableEdit ? <Collapse in={enableEdit} sx={{ width: '100%' }}>
        <Grid container columnGap={1}>
          {address?.map((item, j) =>
            <Grid item xs={item.width}>
              <CustomTextField
                id={`address-${item?.name}`}
                required={item.required}
                fullWidth
                // autoFocus={j === 0}
                type={item?.type}
                sx={{ margin: '0em 0', color: '#000000' }}
                label={item.label}
                placeholder={''}
                value={editedState?.address?.[item?.name]}
                inputProps={{ style: { color: '#000000' } }}
                onChange={(e) => {
                  console.log('e.target.value address::', e.target.value);
                  if (!item.disabled || !disabledFields.includes(item?.name)) {
                    if (item?.name == 'pincode') {
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6);
                    }
                    handleChange(item?.name, e.target.value, 'address');
                  }
                }}
                InputLabelProps={{ shrink: true }}
                // disabled={item.disabled}
                helperText={(errors['address'][item?.name] !== 'required') ?
                  errors['address'][item?.name] : ''}
                error={errors['address']?.[item?.name]?.length > 0}
                onWheel={(e) => {
                  e?.target?.blur();
                }}
              />
            </Grid>)}
          <Grid item xs={6}>
            <Button
              variant="outlined" onClick={async () => {
                await fetchInfo();
                setEnableEdit(!enableEdit);
              }}>
              Reset
            </Button>
          </Grid>
          <Grid item xs>
            <LoadingButton
              id="address-submit"
              variant="contained"
              onClick={() => handleSubmit()}
              disabled={loadingThis || disableDoneButton}
            >
              Done
            </LoadingButton>
          </Grid>


        </Grid>
      </Collapse> : null}


      {/* <LoadingButton
        // loading={loadingYes}
        disabled={!agree}
        variant="contained"
        sx={{ fontWeight: 600, margin: '1em 0' }}
        onClick={() => {
          handleSubmit();
        }}>
        Continue
      </LoadingButton> */}

    </Grid>
  );
});
AddressEditable.displayName = 'AddressEditable';
export default AddressEditable;
